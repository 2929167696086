<template lang="pug">
it-modal.add-products-modal(v-model="modelValue")
  template(#header) Добавить
  
  template(#body)
    .add-products-modal-content
      .add-products-modal-content-actions
        label.add-products-modal-content-actions__all-select
          it-checkbox(v-model="allSelected")
          span(v-show="!allSelected") Выбрать все
          span(v-show="allSelected") Снять все
        query-search.add-products-modal-content-actions__search

      data-table.add-products-modal__table(:headers="headers" :items="filteredProducts" :actions="false" defaultSortFilter="sort")
        template(v-slot:select="{ item }"): it-checkbox(v-model="item.select")
        template(v-slot:sort="{ item }"): it-input(type="number" v-model="item.sort")
        template(v-slot:sellPrice="{ item }"): it-input(type="number" v-model="item.sellPrice")
        template(v-slot:recomendedPrice="{ item }"): it-input(type="number" v-model="item.recomendedPrice")

  template(#actions)
    it-button(type="danger" outlined @click="$emit('update:modelValue')") Отменить
    it-button(type="primary" :loading="loading" @click="addProducts") Добавить
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import QuerySearch from '@/components/QuerySearch'
import DataTable from '@/components/DataTable'
import productsMixins from '@/mixins/products'
import productTypes from '@/assets/productTypes'


export default {
  name: 'AddProductsModal',
  props: {
    modelValue: Boolean,
    priceListId: [String, Number],
    exсludeProductsIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'products:add'],
  components: { QuerySearch, DataTable },
  mixins: [productsMixins],
  data() {
    return {

      headers: [
        {title: '', value: 'select', boolean: true},
        {title: 'id', value: 'id', readonly: true, sortable: true},
        {title: 'iid', value: 'iid', readonly: true, sortable: true},
        {title: 'name', value: 'name', readonly: true, soratble: true},
        {title: 'sort', value: 'sort', sortable: true},
        {title: 'type', value: 'type', inputType: 'select', options: productTypes, readonly: true},
        {title: 'company', value: 'category', readonly: true, sortable: true},
        {title: 'sku group', value: 'skugroup', readonly: true, sortable: true},
        {title: 'price', value: 'price', readonly: true, sortable: true},
        {title: 'sellPrice', value: 'sellPrice'},
        {title: 'recomendedPrice', value: 'recomendedPrice'},
        {title: 'active', value: 'active', boolean: true, readonly: true, sortable: true},
        {title: 'isNew', value: 'isNew', boolean: true, readonly: true, sortable: true}
      ],
      loading: false,

      allSelected: false,
      selectProducts: [],
      currentProducts: [],
      filteredProducts: []
    }
  },

  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
    },
    queryReq: {
      handler() {
        this.createFilteredProducts()
      },
      immediate: true
    },
    products: {
      handler() {
        this.createCurrentProducts()
        this.createFilteredProducts()
      },
      immediate: true
    },
    exсludeProductsIds: {
      handler() {
        this.createCurrentProducts()
        this.createFilteredProducts()
      },
      immediate: true
    },

    allSelected() {
      if ( this.allSelected ) this.currentProducts.forEach( elem => elem.select = true )
      else this.currentProducts.forEach( elem => elem.select = false )
    }
  },

  methods: {
    async addProducts() {
      this.loading = true

      const products = this.filteredProducts.filter( elem => elem.select )
      const reqModels = products.map( elem => ({
        id: elem.id,
        sort: elem.sort,
        sellPrice: elem.sellPrice,
        recomendedPrice: elem.recomendedPrice
      }) )

      try {
        const {data} = await this.$api.post(`/pricelists/${this.priceListId}/products`, {data: reqModels})
        this.$emit('products:add', data.result.data)
        this.$emit('update:modelValue', false)
      } catch (err) {
        console.log(err)
      }

      this.loading = false
    },

    createCurrentProducts() {      
      const products = this.products.filter( elem => !this.exсludeProductsIds.includes(elem.id) )

      this.currentProducts = products.map( elem => ({
        ...elem,
        select: false,
        sellPrice: null,
        recomendedPrice: null
      }) )
    },

    createFilteredProducts() {
      if ( !this.queryReq || this.queryReq === '' ) this.filteredProducts = this.currentProducts
      this.filteredProducts = this.filterByQuery(this.currentProducts)
    }
  }
}
</script>