<template lang="pug">
.page.page-with-table.pricelist-products-page
  .page-header
    router-link(to="/pricelists").page-header__back
      it-button(type="primary" outlined icon="arrow_back") Назад
    .page-header__title Прайслист: <span>{{ priceList?.date }}</span>
    query-search.page-header__search
    it-button.page-header__create-btn(v-if="isAdmin" type="primary" @click="addProductsModal = true") Добавить продукты


  data-table.page__table.pricelist-products-page__table(
    :loading="priceListLoading || productsLoading" :headers="headers" :items="filteredProducts" defaultSortFilter="sort" modelName="product"
    :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="updateProduct"
    :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="deleteProduct")

  add-products-modal(v-if="isAdmin" v-model="addProductsModal" :priceListId="priceList?.id" :exсludeProductsIds="productsIds" @products:add="addProducts")
</template>

<style lang="sass" src="./index.sass" scroped></style>


<script>
import QuerySearch from '@/components/QuerySearch'
import DataTable from '@/components/DataTable'
import AddProductsModal from '@/components/AddProductsModal'


export default {
  name: 'PriceListProductsPage',
  components: { QuerySearch, DataTable, AddProductsModal },
  props: {
    id: [String, Number]
  },

  data() {
    return {
      
      priceListLoading: false,
      priceList: null,

      productsLoading: false,
      headers: [
        {title: 'id', value: 'id', readonly: true, sortable: true},
        {title: 'iid', value: 'iid', readonly: true, sortable: true},
        {title: 'Name', value: 'name', readonly: true, sortable: true},
        {title: 'Sort', value: 'sort', sortable: true},
        {title: 'Type', value: 'type', readonly: true, sortable: true},
        {title: 'Company', value: 'company', readonly:true, sortable: true},
        {title: 'Category', value: 'category', readonly:true, sortable: true},
        {title: 'Sku group', value: 'skugroup', readonly:true, sortable: true},
        {title: 'Price', value: 'price', readonly: true, sortable: true},
        {title: 'SellPrice', value: 'sellPrice', sortable: true},
        {title: 'RecomendedPrice', value: 'recomendedPrice', sortable: true},
        {title: 'isNew', value: 'isNew', boolean: true, readonly: true, sortable: true}
      ],
      products: [],

      addProductsModal: false
    }
  },
  computed: {
    productsIds() {
      return this.products.map( elem => elem.id )
    },

    editApiMethod: () => 'patch',
    deleteApiMethod: () => 'delete',
    editApiUrl() {
      return `/pricelists/${this.id}/products`
    },
    deleteApiUrl() {
      return `/pricelists/${this.id}/products`
    },

    filteredProducts() {
      if ( !this.queryReq || this.queryReq === '' ) return this.products
      return this.filterByQuery(this.products)
    }
  },

  watch: {
    id: {
      handler() {
        this.getPriceList()
        this.getProducts()
      },
      immediate: true
    }
  },


  methods: {
    async getPriceList() {
      this.priceListLoading = true

      try {
        const {data} = await this.$api.get(`/pricelists/${this.id}`)
        this.priceList = data.result.priceList
      } catch (err) {
        console.log(err)
        this.$notification.error(err?.response?.data?.message || err.message)
      }

      this.priceListLoading = false
    },

    async getProducts() {
      this.productsLoading = true

      try {
        const {data} = await this.$api.get(`/pricelists/${this.id}/products`)
        this.products = data.result.data
      } catch(err) {
        console.log(err)
        this.$notification.error(err?.response?.data?.message || err.message)
      }

      this.productsLoading = false
    },

    addProducts(products) {
      this.products = [ ...this.products, ...products ]
      this.$notification.success('Успешно добавлено')
    },

    updateProduct(item) {
      const index = this.products.findIndex( elem => elem.id === item.id )
      this.products[index] = { ...item }
    },
    deleteProduct(id) {
      const index = this.products.findIndex( elem => elem.id === id )
      this.products.splice(index, 1)
    }
  }
}
</script>